import axios from "axios";
import {
  getParametrosProveedor,
  getProveedorSucursal,
  tiposIva,
} from "../../../requests/urls";
import request from "../../../requests/request";

export const getAllData = async (idProveedor) => {
  const defaults = {
    headers: { Authorization: `Token ${localStorage.getItem("token")}` },
  };
  let response = {};
  await axios
    .all([
      axios.get(getProveedorSucursal(idProveedor), defaults),
      axios.get(getParametrosProveedor(idProveedor), defaults),
    ])
    .then(
      axios.spread((proveedor, parametros) => {
        response = {
          proveedor,
          parametros,
        };
      }),
    )
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export const getIvas = async () => {
  try {
    const response = await request({
      method: "GET",
      url: tiposIva,
    });
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};
