import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { parseCurrency } from "../../../../../utils/parsers";
import { styles } from "../../assets/css/styleA4";
import {
  C_COMPROBANTE_ORIGINA_RETENCION,
  C_DATOS_RETENCION_PRACTICADA,
  C_IMPOSIBILIDAD_RETENCION_DESCRIPCION,
  C_IMPOSIBILIDAD_RETENCION_TITULO,
  C_IMPUESTO_GANACIAS,
  C_IMPUESTO_TITULO,
  C_MONTO_COMPROBANTE_ORGINA_RETENCION,
  C_MONTO_RETENCION_TITULO,
  C_REGIMEN_DESCRIPCION,
  C_REGIMEN_TITULO,
} from "../Constants/constants";

export const DatosRetencionPracticada = ({ data }) => {
  const stylesPdf = StyleSheet.create({
    fontBoldTitle: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 18,
    },
    fontBold: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 13,
    },
  });

  return (
    <View style={{ paddingTop: 20 }}>
      <Text style={stylesPdf.fontBold}>{C_DATOS_RETENCION_PRACTICADA}</Text>

      <View style={[styles.containerRowRetencion, { paddingTop: 15 }]} fixed>
        <Text style={stylesPdf.fontBold}>{C_IMPUESTO_TITULO}</Text>
        <Text style={{ fontSize: 12 }}>{C_IMPUESTO_GANACIAS}</Text>
      </View>

      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>{C_REGIMEN_TITULO}</Text>
        <Text style={{ fontSize: 12 }}>{C_REGIMEN_DESCRIPCION}</Text>
      </View>

      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>
          {C_COMPROBANTE_ORIGINA_RETENCION}
        </Text>
        <Text style={{ fontSize: 12 }}>{data.pago_compra.id_pagocompra}</Text>
      </View>

      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>
          {C_MONTO_COMPROBANTE_ORGINA_RETENCION}
        </Text>
        <Text style={{ fontSize: 12 }}>
          {data.pago_compra.monto_pago
            ? parseCurrency(data.pago_compra.monto_pago)
            : "0"}
        </Text>
      </View>

      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>{C_MONTO_RETENCION_TITULO}</Text>
        <Text style={{ fontSize: 12 }}>
          {data.monto_retencion ? parseCurrency(data.monto_retencion) : "0"}
        </Text>
      </View>

      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>
          {C_IMPOSIBILIDAD_RETENCION_TITULO}
        </Text>
        <Text style={{ fontSize: 12 }}>
          {C_IMPOSIBILIDAD_RETENCION_DESCRIPCION}
        </Text>
      </View>
    </View>
  );
};

export default DatosRetencionPracticada;
