import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Backdrop,
  makeStyles,
  Typography,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import {
  deleteClient,
  informeCtaCte,
  informeVtasAdeudas,
  getListClienteSucursal,
  modificarCuentaCorriente,
  excelAgendaClientesURL,
} from "../../requests/urls";
import {
  filterCliente,
  formatClientes,
  BodyModalCuentaCorriente,
} from "./utils";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../components/ait-reusable/HandlePdf/handlePdf";
import { getPdfBlob } from "../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import { useDispatch, useSelector } from "react-redux";
import { firtDayCurrenMonth, lastDayCurrenMonth } from "../../utils/dates";
import { imprimirInformeService } from "./cuentaCorrienteServices";
import { updateBackClient } from "../../Redux/Actions/actionClienteHistory";
import { updateCliente } from "../../Redux/Actions/presupuestoActions";
import { useDebounce } from "../../customHooks/useDebounce";
import { Showmodal } from "../../components/ait-reusable/PDF/Components/PreviewPdf";
import axios from "axios";
import request from "../../requests/request";
import Filters from "./Components/Filters";
import PageTitle from "../../components/common/PageTitle";
import InfoCliente from "./InfoCliente";
import ReportTable from "../../components/ait-reusable/Report/ReportTable";
import ModalPeriodo from "./Modales/ModalPeriodo";
import ModalConfirm from "../../components/ait-reusable/ModalConfirm/ModalConfirm";
import HeaderCliente from "./HeaderCliente";
import ModalWhatsApp from "../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import FooterInfoCliente from "./FooterInfoCliente";
import BodyModalBajaCliente from "./BodyModalBajaCliente";
import "./assets/styles.css";
import DeudasTotales from "./DeudasTotales";
import { tienePermisos } from "../../utils/tienePermisos";
import { PERMISOS } from "../../constantes/permisos";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Clientes = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cliente } = useSelector((state) => state.presupuesto);
  const { clientHistory, isBack } = useSelector(
    (state) => state.clienteHistory,
  );

  const [search, setSearch] = useState("");
  const [clientes, setClientes] = useState([]);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ordenFiltro, setOrdenFiltro] = useState(null);
  const [movimientos, setMovimientos] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(null);
  const [modalPeriodo, setModalPeriodo] = useState(false);
  const [openModalBaja, setOpenModalBaja] = useState(false);
  const [modalWhatsapp, setModalWhatsapp] = useState(false);
  const [clienteSelected, setClienteSelected] = useState(null);
  const [movCuentaCorriente, setMovCuentaCorriente] = useState([]);
  const [loadingMovimientos, setLoadingMovimientos] = useState(false);
  const [loadingCuentaCorriente, setLoadingCuentaCorriente] = useState(false);
  const [loadingExcelAgenda, setLoadingExcelAgenda] = useState(false);
  const [openModalCuentaCorriente, setOpenModalCuentaCorriente] =
    useState(false);

  const [datapdf, setDatapdf] = useState({
    ctacte: null,
    vtas: null,
  });

  const [dates, setDates] = useState({
    fromDate: firtDayCurrenMonth(),
    toDate: lastDayCurrenMonth(),
  });

  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const [loadingBaja, setLoadingBaja] = useState(false);
  const [dataPdfFetched, setDataPdfFetched] = useState(null);
  const [urlPdf, setUrlPdf] = useState(null);
  const [openModalWhatsappPDF, setOpenModalWhatsappPDF] = useState(false);
  const [othersFilters, setOthersFilters] = useState({
    ctaCte: "todos",
    tipoCliente: "todos",
    tipoDeCliente: "todos",
    activos: 1,
    transporte: null,
  });

  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { empleado } = useSelector((state) => state.loginReducer);

  const debouncedSearchTerm = useDebounce(search, 350);
  const tienePermisosCliente = tienePermisos(empleado, PERMISOS.CLIENTES);

  let columns = tienePermisosCliente
    ? [
        "Nro.",
        "Última compra",
        "Último pago",
        "Cliente",
        "Estado",
        "CUIT/DNI",
        "Saldo",
        "--",
      ]
    : [
        "Nro.",
        "Última compra",
        "Último pago",
        "Cliente",
        "Estado",
        "CUIT/DNI",
        "--",
      ];

  const downloadExcelAgendaClientes = async () => {
    setLoadingExcelAgenda(true);
    const fileName = `Agenda de clientes.xlsx`;

    axios({
      url: excelAgendaClientesURL,
      params: {
        blank: 1,
        q: debouncedSearchTerm,
        meli: 0,
        activo: othersFilters.activos,
        ordenFiltro: ordenFiltro,
        cuenta_corriente:
          othersFilters.ctaCte === "todos" ? null : othersFilters.ctaCte,
        tipo_cliente:
          othersFilters.tipoCliente === "todos"
            ? null
            : othersFilters.tipoCliente,
        tipo_de_cliente:
          othersFilters.tipoDeCliente === "todos"
            ? null
            : othersFilters.tipoDeCliente,
        transporte: othersFilters.transporte
          ? othersFilters.transporte.id
          : null,
      },
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        link.click();
        setLoadingExcelAgenda(false);
      })
      .catch((err) => {
        console.log(err);
        errorNotification(
          "No se ha podido descargar el Excel, intente nuevamente.",
        );
        setLoadingExcelAgenda(false);
      });
  };

  const toggle = () => {
    setDates({
      ...dates,
      fromDate: firtDayCurrenMonth(),
      toDate: lastDayCurrenMonth(),
    });
    setPreview(false);
    setMovimientos(null);
  };

  const toggleModalBaja = () => setOpenModalBaja(!openModalBaja);

  const getClientes = async (ordenFiltro) => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getListClienteSucursal,
        params: {
          page: pagination.page,
          blank: 1,
          q: debouncedSearchTerm,
          meli: 0,
          activo: othersFilters.activos,
          ordenFiltro: ordenFiltro,
          cuenta_corriente:
            othersFilters.ctaCte === "todos" ? null : othersFilters.ctaCte,
          tipo_cliente:
            othersFilters.tipoCliente === "todos"
              ? null
              : othersFilters.tipoCliente,
          tipo_de_cliente:
            othersFilters.tipoDeCliente === "todos"
              ? null
              : othersFilters.tipoDeCliente,
          transporte: othersFilters.transporte
            ? othersFilters.transporte.id
            : null,
        },
      });
      handleResponseClientes(response);
    } catch (error) {
      console.error(error);
      clearState();
    }
  };

  const handleClickCliente = (c) => {
    setClienteSelected(filterCliente(c.id, clientes));
  };

  const deleteCliente = async () => {
    setLoadingBaja(true);
    try {
      const response = await request({
        method: "DELETE",
        url: deleteClient(clienteSelected.idClienteDeSucursal),
        params: {
          activo: Number(!clienteSelected.activo),
        },
      });
      handleDeleteCliente(response);
    } catch (error) {
      console.error(error);
      setLoadingBaja(false);
      errorNotification("Ocurrio un error.");
    }
  };

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setClientes([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const handleDeleteCliente = (response) => {
    if (response.status === 200) {
      successNotification(
        `Cliente dado de ${
          clienteSelected.activo ? "baja" : "alta"
        } exitosamente.`,
      );
      setLoadingBaja(false);
      toggleModalBaja();
      setClienteSelected(null);
      getClientes(ordenFiltro);
    }
  };

  const handlePutCuentaCorriente = async () => {
    setLoadingCuentaCorriente(true);
    try {
      const response = await request({
        method: "PUT",
        url: modificarCuentaCorriente(clienteSelected.idClienteDeSucursal),
        data: {
          cuenta_corriente: Number(!clienteSelected.cuenta_corriente),
        },
      });
      handleResponsePutCuentaCorriente(response);
      successNotification("Cuenta corriente modificada exitosamente.");
    } catch (error) {
      console.error(error);
      errorNotification("No se ha podido actualizar.");
      setLoadingCuentaCorriente(false);
    }
  };

  const handleResponsePutCuentaCorriente = (response) => {
    const { status } = response;
    if (status === 201) {
      getClientes(ordenFiltro);
      setClienteSelected({
        ...clienteSelected,
        cuenta_corriente: response.data.data.cuenta_corriente,
      });

      setLoadingCuentaCorriente(false);
      setOpenModalCuentaCorriente(false);
      updateCuentaCorrienteEnVentas(response);
    } else {
      setLoadingCuentaCorriente(false);
    }
  };

  const updateCuentaCorrienteEnVentas = (response) => {
    const newClient = {
      ...cliente,
      cuenta_corriente: response.data.data.cuenta_corriente,
    };
    if (cliente) {
      if (cliente.idClienteDeSucursal === clienteSelected.idClienteDeSucursal) {
        dispatch(updateCliente(newClient));
      }
    }
  };

  const handleResponseClientes = (response) => {
    if (response.status === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setClientes(data.items);
      setPagination({
        ...pagination,
        num_items: data.num_items,
        next_page: data.next_page,
      });
    } else {
      clearState();
    }
  };

  const handleSearch = ({ target }) => {
    const { value } = target;
    setPagination({ ...pagination, page: 1 });
    setSearch(value);
  };

  const handleCloseModalCuentaCorriente = () => {
    setOpenModalCuentaCorriente(!openModalCuentaCorriente);
  };

  const printInforme = async (idCliente) => {
    const token = localStorage.getItem("token");
    const defaultHeaders = { Authorization: `Token ${token}` };
    await axios
      .all([
        axios.get(informeCtaCte(idCliente), { headers: defaultHeaders }),
        axios.get(informeVtasAdeudas(idCliente), { headers: defaultHeaders }),
      ])
      .then(
        axios.spread((response_ctacte, response_vtas) => {
          if (response_ctacte.status === 200 && response_vtas.status === 200) {
            setDatapdf({
              ctacte: response_ctacte.data,
              vtas: response_vtas.data,
            });
            setPreview(true);
          }
        }),
      )
      .catch((error) => console.error(error));
  };

  const handleImprimirInforme = async (formato) => {
    setLoadingMovimientos(true);

    try {
      const response = await imprimirInformeService(clienteSelected, dates);
      setMovCuentaCorriente(response.data);
      if (response.data.movimientos.length) {
        setModalPeriodo(false);
        setLoadingMovimientos(false);
        setOpenBackdrop(true);

        let newData = { ...response.data, periodo: dates };

        if (formato === "print") {
          const blobPdf = await getBlobPdf("cuentaCorrienteCliente", newData);
          if (configGeneral.previsualizacion) {
            imprimirPdf(blobPdf);
          } else {
            setMovimientos(true);
            imprimirPdf(blobPdf);
          }
          setOpenBackdrop(false);
        } else {
          handleOpenModalPdf(newData);
        }
      }
      if (!response.data.movimientos.length) {
        errorNotification("No hay movimientos para mostrar");
        setLoadingMovimientos(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModalPdf = (newData) => {
    setDataPdfFetched(newData);
  };

  const handleOpenModalWhatsApp = (cliente) => {
    setModalWhatsapp(true);
    setClienteSelected(cliente);
  };

  const onCloseModalPeriodo = () => {
    setDates({
      ...dates,
      fromDate: firtDayCurrenMonth(),
      toDate: lastDayCurrenMonth(),
    });
    setModalPeriodo(false);
  };

  useEffect(() => {
    getClientes(ordenFiltro);
  }, [debouncedSearchTerm, pagination.page]);

  useEffect(() => {
    if (movimientos) {
      setModalPeriodo(false);
    }
  }, [movimientos]);

  useEffect(() => {
    if (clientHistory && isBack) {
      setClienteSelected(clientHistory);
      setPagination({ ...pagination, page: 1 });
      setSearch(clientHistory.idCliente.razonSocial);
      dispatch(updateBackClient(false));
    }
  }, []);

  useEffect(() => {
    if (urlPdf) {
      setOpenModalWhatsappPDF(true);
    } else if (dataPdfFetched) {
      getPdfBlob("cuentaCorrienteCliente", dataPdfFetched, configGeneral).then(
        (res) => {
          if (res) {
            setUrlPdf(res.secure_url);
          }
          setOpenBackdrop(false);
        },
      );

      setDataPdfFetched(null);
      // setOpenBackdrop(false);
    }
  }, [urlPdf, dataPdfFetched]);

  useEffect(() => {
    localStorage.setItem("ordenFiltro", ordenFiltro);
  }, [ordenFiltro]);

  return (
    <Grid className="px-4">
      <Grid container spacing={2} className="page-columns py-4">
        <PageTitle
          sm={4}
          title="Cliente"
          subtitle="Listado CLIENTES"
          className="text-sm-left"
        />
      </Grid>

      <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
        <Filters
          getClientes={getClientes}
          ordenFiltro={ordenFiltro}
          setOrdenFiltro={setOrdenFiltro}
          othersFilters={othersFilters}
          setOthersFilters={setOthersFilters}
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <ReportTable
            className={"pointer-table"}
            loading={loading}
            items={formatClientes(
              clientes,
              props.history,
              printInforme,
              setModalPeriodo,
              setClienteSelected,
              handleOpenModalWhatsApp,
              tienePermisosCliente,
            )}
            columns={columns}
            pagination={pagination}
            setPagination={setPagination}
            handleClickRow={handleClickCliente}
          >
            <HeaderCliente
              search={search}
              handleSearch={handleSearch}
              props={props}
              empleado={empleado}
              loadingExcelAgenda={loadingExcelAgenda}
              downloadExcelAgendaClientes={downloadExcelAgendaClientes}
            />
          </ReportTable>
        </Grid>

        <Grid item xs={12} sm={12} lg={3} md={3} className={"text-center"}>
          {tienePermisosCliente && <DeudasTotales />}
          <Card>
            <CardContent>
              <Typography variant="h6">Detalles cliente</Typography>
              {clienteSelected && (
                <div style={{ borderBotton: 0 }}>
                  <InfoCliente
                    comision={clienteSelected.comision}
                    idCliente={clienteSelected.idCliente}
                    descuento={clienteSelected.descuento_prefijado}
                    saldo={
                      parseFloat(clienteSelected.monto_billetera) -
                      parseFloat(clienteSelected.deuda)
                    }
                  />
                  {clienteSelected && (
                    <FooterInfoCliente
                      {...props}
                      history={props.history}
                      cliente={clienteSelected}
                      getClientes={getClientes}
                      setCliente={handleClickCliente}
                      toggle={toggleModalBaja}
                      handlePutCuentaCorriente={handlePutCuentaCorriente}
                      loadingCuentaCorriente={loadingCuentaCorriente}
                      setOpenModalCuentaCorriente={setOpenModalCuentaCorriente}
                      tienePermisosCliente={tienePermisosCliente}
                    />
                  )}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {openModalBaja && clienteSelected && (
        <ModalConfirm
          title={`Baja de cliente Nº${clienteSelected.idClienteDeSucursal}`}
          open={openModalBaja}
          handleClose={toggleModalBaja}
          handleSubmit={deleteCliente}
          body={<BodyModalBajaCliente cliente={clienteSelected} />}
          buttons={{ cancel: "CANCELAR", submit: "CONFIRMAR" }}
          disabled={loadingBaja}
        />
      )}

      {movimientos && (
        <Showmodal
          preview={movimientos}
          toggle={toggle}
          data={movCuentaCorriente}
          tipo={"cuentaCorrienteCliente"}
        />
      )}

      {preview && (
        <Showmodal
          tipo={"reporte A4 ctacte"}
          preview={preview}
          toggle={toggle}
          data={datapdf}
        />
      )}

      {openModalCuentaCorriente &&
        clienteSelected &&
        (clienteSelected.deuda !== 0 ||
          clienteSelected.monto_billetera !== "0.00") && (
          <ModalConfirm
            title={`${
              clienteSelected.cuenta_corriente ? "Desactivar" : "Activar"
            } la cuenta corriente del cliente N°${
              clienteSelected.idClienteDeSucursal
            }`}
            open={openModalCuentaCorriente}
            handleClose={handleCloseModalCuentaCorriente}
            handleSubmit={handlePutCuentaCorriente}
            body={<BodyModalCuentaCorriente cliente={clienteSelected} />}
            buttons={{ cancel: "CANCELAR", submit: "CONFIRMAR" }}
            disabled={loadingCuentaCorriente}
          />
        )}

      {modalPeriodo && (
        <ModalPeriodo
          dates={dates}
          setDates={setDates}
          modalPeriodo={modalPeriodo}
          onClose={onCloseModalPeriodo}
          setModalPeriodo={setModalPeriodo}
          loadingMovimientos={loadingMovimientos}
          handleImprimirInforme={handleImprimirInforme}
        />
      )}

      {modalWhatsapp && (
        <ModalWhatsApp
          open={modalWhatsapp}
          close={() => setModalWhatsapp(false)}
          telefono={clienteSelected.idCliente.telefonoContacto}
          nombre={clienteSelected.idCliente.razonSocial}
          editar={`/clientes/modificar/${clienteSelected.idClienteDeSucursal}/`}
        />
      )}

      {openBackdrop && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {openModalWhatsappPDF && (
        <ModalWhatsApp
          open={openModalWhatsappPDF}
          close={() => {
            setOpenModalWhatsappPDF(false);
            setClienteSelected(null);
            setOpenBackdrop(false);
            setUrlPdf(null);
            setDataPdfFetched(null);
          }}
          urlPdf={urlPdf}
          telefono={
            clienteSelected && clienteSelected.idCliente.telefonoContacto
          }
          nombre={clienteSelected && clienteSelected.idCliente.razonSocial}
          mensajePdf={`¡Hola${
            clienteSelected && clienteSelected.idCliente.razonSocial
              ? ` ${clienteSelected.idCliente.razonSocial}!`
              : "!"
          } Te envíamos a continuación el reporte de cuenta corriente y ventas adeudadas en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
          editar={`/clientes/modificar/${clienteSelected.idClienteDeSucursal}/`}
        />
      )}
    </Grid>
  );
};

export default Clientes;
