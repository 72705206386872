import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Spinner from "../../components/Spinner";

const InfoCliente = ({ idCliente, saldo, descuento, comision }) => {
  const [loading, setLoading] = useState(true);
  const {
    razonSocial,
    CUIT,
    numeroDocumento,
    telefonoContacto,
    domicilio,
    correoElectronico,
    idEstadoTributario,
    deudaMaxima,
    provincia,
    responsable_cuenta,
    tipo_cliente,
  } = idCliente;

  const infoCliente = [
    {
      name: razonSocial,
      icon: "face",
    },
    {
      name: responsable_cuenta || "---",
      icon: "face",
    },
    {
      name: CUIT || numeroDocumento,
      icon: "fingerprint",
    },
    {
      name: correoElectronico || "---",
      icon: "alternate_email",
    },
    {
      name: telefonoContacto || "---",
      icon: "call",
    },

    {
      name:
        (domicilio || "---") +
        ", " +
        (provincia ? provincia.nombre : "SIN PROVINCIA"),
      icon: "location_on",
    },
    {
      name: tipo_cliente ? tipo_cliente.nombre : "...",
      icon: "attribution",
    },
    {
      name: (idEstadoTributario && idEstadoTributario.descripcion) || "---",
      icon: "account_balance",
    },
    {
      name: `Saldo $ ${
        Number(saldo) === 0 ? saldo : Number(saldo).toLocaleString("es-AR")
      }`,
      icon: "credit_card",
    },
    {
      name: `Deuda Máxima $ ${
        Number(deudaMaxima) === 0
          ? deudaMaxima
          : Number(deudaMaxima).toLocaleString("es-AR")
      }`,
      icon: "warning",
    },
    {
      name: `${
        descuento ? "Descuento del " + descuento.nombre : "Sin descuento"
      }`,
      icon: descuento
        ? "sentiment_very_satisfied"
        : "sentiment_very_dissatisfied",
    },
    {
      name: `${
        Number(comision) > 0
          ? "Comisión del " + Number(comision).toFixed(2) + "%"
          : "Sin comisión"
      }`,
      icon: "build",
    },
  ];

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [idCliente]);

  return (
    <List dense={false} className="pt-2">
      {!loading ? (
        <>
          {infoCliente.map((item, index) => {
            return (
              <ListItem key={index} className="p-1">
                <ListItemIcon>
                  <Icon>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary={item.name} />
              </ListItem>
            );
          })}
        </>
      ) : (
        <Box p={3}>
          <Spinner color="#66b3ff" type="spin" />
        </Box>
      )}
    </List>
  );
};

export default InfoCliente;
