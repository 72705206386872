import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addClienteHistory } from "../../Redux/Actions/actionClienteHistory";
import { Grid } from "@material-ui/core";
import ButtonGeneral from "../../components/ait-reusable/Button/ButtonGeneral";

const FooterInfoCliente = ({
  history,
  cliente,
  handlePutCuentaCorriente,
  loadingCuentaCorriente,
  toggle,
  setOpenModalCuentaCorriente,
  tienePermisosCliente,
}) => {
  const dispatch = useDispatch();
  const { perfil } = useSelector((state) => state.loginReducer);
  const [isVendedor, setIsVendedor] = useState(false);

  useEffect(() => {
    const empleado = localStorage.getItem("empleado");
    const empleadoData = JSON.parse(empleado);
    if (empleadoData.rol === "Vendedor") {
      setIsVendedor(true);
    } else {
      setIsVendedor(false);
    }
  }, []);

  const handleClickCuentaCorriente = () => {
    if (cliente.deuda !== 0 || cliente.monto_billetera !== "0.00") {
      setOpenModalCuentaCorriente(true);
    } else {
      handlePutCuentaCorriente();
    }
  };

  const colorButtonCuentaCorriente = () => {
    let color;
    if (cliente.cuenta_corriente) {
      color = "rgb(196, 24, 60)";
    } else {
      color = "rgb(23, 198, 113)";
    }
    return color;
  };

  const colorButtonEstado = () => {
    let color;
    if (cliente.activo) {
      color = "rgb(196, 24, 60)";
    } else {
      color = "rgb(23, 198, 113)";
    }
    return color;
  };

  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: "0.5em" }}>
        {tienePermisosCliente && (
          <Grid item xs={12}>
            <ButtonGeneral
              disabled={false}
              click={() => {
                dispatch(addClienteHistory(cliente));
                history.push(
                  `/cliente/billetera-virtual/${cliente.idClienteDeSucursal}/`,
                );
              }}
              message="BILLETERA VIRTUAL"
              fullwidth={true}
            />
          </Grid>
        )}

        {tienePermisosCliente && (
          <Grid item xs={12}>
            <ButtonGeneral
              disabled={cliente.cantidad_facturas_adeudadas === 0}
              click={(event) => {
                dispatch(addClienteHistory(cliente));
                history.push(
                  `/facturas-adeudadas/${cliente.idClienteDeSucursal}`,
                );
              }}
              message={`COMPROBANTES PENDIENTES (
            ${
              cliente.cantidad_facturas_adeudadas
                ? cliente.cantidad_facturas_adeudadas
                : "0"
            }
            )`}
              fullwidth={true}
            />
          </Grid>
        )}

        {tienePermisosCliente && (
          <Grid item xs={12}>
            <ButtonGeneral
              click={(event) => {
                dispatch(addClienteHistory(cliente));
                history.push(`/cliente/pagos/${cliente.idClienteDeSucursal}/`);
              }}
              message={"HISTORIAL PAGOS"}
              disabled={false}
              fullwidth={true}
            />
          </Grid>
        )}

        {tienePermisosCliente && (
          <Grid item xs={12}>
            <ButtonGeneral
              disabled={false}
              click={(event) => {
                dispatch(addClienteHistory(cliente));
                history.push(
                  `/cliente/cheques/${cliente.idClienteDeSucursal}/`,
                );
              }}
              message={"HISTORIAL CHEQUES"}
              fullwidth={true}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ButtonGeneral
            disabled={false}
            click={(event) => {
              history.push(
                `/clientes/modificar/${cliente.idClienteDeSucursal}/`,
              );
            }}
            message={"EDITAR CLIENTE"}
            fullwidth={true}
          />
        </Grid>

        {!isVendedor && (
          <Grid item xs={12}>
            <ButtonGeneral
              style={{ backgroundColor: colorButtonCuentaCorriente() }}
              message={
                cliente.cuenta_corriente
                  ? "DESACTIVAR CUENTA CORRIENTE"
                  : "ACTIVAR CUENTA CORRIENTE"
              }
              fullwidth={true}
              disabled={loadingCuentaCorriente}
              click={() => handleClickCuentaCorriente()}
            />
          </Grid>
        )}

        {!isVendedor && (
          <Grid item xs={12}>
            <ButtonGeneral
              message={cliente.activo ? "DAR DE BAJA" : "DAR DE ALTA"}
              style={{ backgroundColor: colorButtonEstado() }}
              click={() => toggle()}
              fullwidth={true}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FooterInfoCliente;
