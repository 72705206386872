import React from "react";
import { Button, Grid } from "@material-ui/core";
export default function BotonFacturasAdeudadas({ cliente, idCliente, props }) {
  return (
    <Grid item xs={12} sm={12} lg={4}>
      {cliente && cliente.cantidad_facturas_adeudadas !== 0 ? (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className="mb-2 font-weight-bold"
          size="lg"
          onClick={() =>
            cliente && cliente.deuda > 0
              ? props.history.push(`/facturas-adeudadas/${idCliente}`)
              : ""
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <span className="material-icons mr-1">receipt_long</span>
            COMPROBANTES PENDIENTES
          </div>
        </Button>
      ) : (
        <Button
          disabled={true}
          variant="contained"
          fullWidth
          style={{
            fontSize: 14,
            backgroundColor: "#e0e0e0",
            marginRadius: ".625rem",
            marginBottom: 3,
            fontWeight: 700,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <span className="material-icons mr-1">receipt_long</span>
            NO POSEE COMPROBANTES PENDIENTES
          </div>
        </Button>
      )}
    </Grid>
  );
}
