import React, { useState } from "react";
import { Grid, Card, TextField } from "@material-ui/core";
import AutocompleteEmpleado from "../../../../components/ait-reusable/AutocompleteEmpleado";
import { useSelector, useDispatch } from "react-redux";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import request from "../../../../requests/request";
import { saldarVentas } from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { useMedioPagos } from "../../../../customHooks/useMedioPagos";
import { getComprobante } from "../../../../Redux/Actions/actionPDF";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";

export default function OthersComponents({
  dataEmpleado,
  observacion,
  setObservacion,
  selectionList,
  tipo,
  getValueSaldar,
  newMontos,
  convertNumeroSinMiles,
  facturas,
  closePago,
  setMontos,
  setErrormonto,
  montocobrar,
  setMontocobrar,
  cliente,
  getFacturasAdeudadas,
  updateResumen,
  setDataErrorAFIP,
}) {
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setLoding] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { empleado } = useSelector((state) => state.loginReducer);
  const { mediosPago } = useMedioPagos();
  const dispatch = useDispatch();
  const { comprobante_pdf } = useSelector((store) => store.pDF);
  const { selection, setSelection } = selectionList;

  const _disabled = () => {
    let disabled = false;
    if (disabled) disabled = true;
    if (selectionList.selection.length === 0) disabled = true;
    //Si las ventas seleccionadas son con descuento por plazo
    if (tipo.disabledSelectSinRecargo && !tipo.disabledSelectRecargo) {
      //Si la deuda total es mayor al saldo de billetera no debe poder registrar el pago
      if (getValueSaldar().monto === "") disabled = true;
    }

    //Si las ventas seleccionadas son sin descuento por plazo
    if (!tipo.disabledSelectSinRecargo && tipo.disabledSelectRecargo) {
      let mTotal = Number(
        newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
      );

      if (mTotal > 0 && (montocobrar === "" || montocobrar <= 0))
        disabled = true;
      if (getValueSaldar().msg === "No cuentas con saldo suficiente")
        disabled = true;
    }
    if (getValueSaldar().msg === "La cantidad ingresada se excede al total")
      disabled = true;

    return disabled;
  };

  const handleSubmit = async () => {
    if (dataEmpleado.useEmpleado.empleado) {
      setDisabled(true);
      setLoding(true);
      if (!_disabled()) {
        try {
          const response = await request({
            method: "PUT",
            url: saldarVentas(cliente.id),
            data: {
              ventas: facturas,
              monto_total: tipo.disabledSelectSinRecargo
                ? getValueSaldar().monto
                : convertNumeroSinMiles(montocobrar),
              responsable:
                dataEmpleado && dataEmpleado.useEmpleado.empleado.idEmpleado,
              observacion: observacion === "" ? null : observacion,
              is_pago_con_descuento:
                newMontos.descuentoUnico > 0 ? true : false,
              medio_pago: mediosPago.filter(
                (m) => m.nombre === "Cuenta Corriente",
              )[0].idMedioPago,
            },
          });
          handleResponse(response);
          closePago();
          setLoding(false);
        } catch (err) {
          setDisabled(false);
          console.log(err);
          let msgError = JSON.stringify(err.response.data);

          if (
            msgError.includes(
              "Errores al validar las siguientes notas de crédito",
            )
          ) {
            actionErrorNC(msgError);
          } else {
            errorNotification("Ha ocurrido un error durante el registro.");
          }
          setLoding(false);
        }
      } else {
        setDisabled(false);
      }
    } else {
      setErrorResponsable(true);
    }
  };

  const actionErrorNC = (msgError) => {
    const regexInformacion = /Factura ID: (\S+) Nota de crédito ID: (\d+)/g;

    // Crear un objeto para almacenar elementos únicos basados en los campos 'fact' y 'nc'
    const informacionUnica = {};

    let match;
    while ((match = regexInformacion.exec(msgError)) !== null) {
      const fact = match[1];
      const nc = match[2];
      const key = `${fact}-${nc}`;

      if (!informacionUnica[key]) {
        informacionUnica[key] = { nroFactura: fact, idNotaCredito: nc };
      }
    }

    const informacionUnicaArray = Object.values(informacionUnica);
    errorNotification(
      "Factura generada con éxito pero ocurrieron errores en las validaciones de AFIP de notas de crédito.",
      3000,
    );

    setDataErrorAFIP((prev) => ({
      ...prev,
      facturas: informacionUnicaArray,
      open: true,
    }));
  };

  const handleResponse = async (response) => {
    setDisabled(false);
    if (response.status === 200) {
      dispatch(getComprobante(response.data.recibos, comprobante_pdf));
      setMontocobrar("");
      setSelection([]);
      setMontos.setNewMontos({
        subtotal: 0,
        descuento: 0,
        descuentoUnico: 0,
      });
      setObservacion("");
      dataEmpleado.useEmpleado.empleado &&
        localStorage.setItem(
          "responsable_venta",
          dataEmpleado.useEmpleado.empleado.idEmpleado,
        );
      setErrormonto("");
      successNotification("El pago se ha realizado con éxito.");
    } else {
      errorNotification("Ha ocurrido un error durante el registro.");
    }
    getFacturasAdeudadas();
    updateResumen();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2} className="p-2">
              <Grid item xs={12}>
                <AutocompleteEmpleado
                  useEmpleado={dataEmpleado.useEmpleado}
                  useQueryEmpleado={dataEmpleado.useQueryEmpleado}
                  errorResponsable={errorResponsable}
                  setErrorResponsable={setErrorResponsable}
                  empleado={empleado}
                  setLoading={setLoading}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  size="small"
                  style={{ backgroundColor: "white" }}
                  name="observacion"
                  fullWidth
                  multiline
                  inputProps={{ maxLength: 150 }}
                  label={"Observación"}
                  value={observacion}
                  onChange={(event) => setObservacion(event.target.value)}
                  helperText={`${observacion.length} de 150 caracteres`}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ButtonGeneral
            fullwidth={true}
            message="REGISTRAR PAGO"
            disabled={_disabled() || isLoading}
            click={(e) => handleSubmit(e)}
          />
        </Grid>
      </Grid>
      <CircularBackdrop openBackdrop={isLoading} />
    </>
  );
}
