import { Document, Font, Page, Text } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { styles } from "../assets/css/styleA4";
import { DatosAgenteSujeto } from "./Components/DatosAgenteSujeto";
import DatosRetencionPracticada from "./Components/DatosRetencionPracticada";
import Footer from "./Components/Footer";
import { Header } from "./Components/Header";
import {
  CERTIFICADO_NRO,
  DOCUMENT_TITLE,
  FECHA_RETENCION,
  RETENCIONES_TITULO,
} from "./Constants/constants";

export const RetencionPdf = ({ data }) => {
  Font.register({
    family: "Times-Bold",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/times-bold@0.1.3/fonts/times-bold-regular.ttf",
      },
    ],
  });

  Font.register({
    family: "Opens Sans",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
        fontWeight: 600,
      },
    ],
  });

  return (
    <Document title={DOCUMENT_TITLE}>
      <Page
        style={{
          paddingTop: 20,
          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 20,
        }}
        size={"A4"}
      >
        <Header
          title={RETENCIONES_TITULO}
          subtitle={`
          ${CERTIFICADO_NRO} ${data.id}
          ${FECHA_RETENCION} ${moment(data.fecha_retencion).format("DD-MM-YYYY")}
          `}
        />

        <DatosAgenteSujeto
          nombre={data.sucursal.razonSocial}
          cuit={data.sucursal.CUIT}
          domicilio={data.sucursal.domicilio ? data.sucursal.domicilio : ""}
        />

        <DatosAgenteSujeto
          sujeto={true}
          nombre={`${data.proveedor.razonSocial} ${data.proveedor.alias}`}
          cuit={data.proveedor.CUIT || ""}
          domicilio={data.proveedor.domicilio || ""}
        />

        <DatosRetencionPracticada data={data} />

        <Footer />

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
