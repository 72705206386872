import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tabText: {
    maxWidth: "100%",
  },
});

export default function TabsPago({ tab, setTab, proveedor, tieneAoM }) {
  const classes = useStyles();
  const tabs = [
    { value: 0, label: "PAGAR", disabled: false },
    {
      value: 1,
      label: "PAGOS Y RETENCIONES",
      disabled: tieneAoM && proveedor.calcular_retencion ? false : true,
    },
    { value: 2, label: "COMPROBANTES SELECCIONADOS", disabled: false },
  ];

  const handleOnChangeTab = (event, value) => {
    setTab(value);
  };
  return (
    <Tabs
      indicatorColor="primary"
      value={tab}
      textColor="primary"
      onChange={handleOnChangeTab}
      aria-label="simple tabs example"
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          disabled={tab.disabled}
        />
      ))}
    </Tabs>
  );
}
