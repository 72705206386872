import React, { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FiltroBuscador from "../../Proveedores/Catalogo/FiltroBuscador";
import FiltroStock from "../../Proveedores/Catalogo/FiltroStock";
import TextFieldAutocompleteArticulo from "../../../components/ait-reusable/TextFieldAutocompleteArticulo";
import useGetTiposCliente from "../../../customHooks/useGetTiposCliente";
import { useProvincias } from "../../../customHooks/useProvincias";
import AutocompleteTransporteFiltro from "../../../components/ait-reusable/AutocompleteTransporteFiltro";

export default function OthersFields({
  medioSelected,
  filters,
  dataMP,
  dataTarjeta,
  dataTC,
  loading,
  dataClient,
  dataEmpleado,
  dataNC,
  dataArticle,
  dataFiltro,
  dataTipoVenta,
  checks,
  handleChangeFiltro,
  tipoCliente,
  setTipoCliente,
  setProvincia,
  handleChangeTransporte,
}) {
  // const { tiposCliente } = useGetTiposCliente();
  const { provincias } = useProvincias();

  const [descripcionProvincia, setDescripcionProvincia] = useState("");

  const filterProvincias = () => {
    let newProvincias = provincias.slice();
    return newProvincias.filter((prov) =>
      prov.nombre.toUpperCase().includes(descripcionProvincia.toUpperCase()),
    );
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={
          medioSelected &&
          (medioSelected.nombre === "Tarjeta Crédito" ||
            medioSelected.nombre === "Tarjeta Débito")
            ? 3
            : 4
        }
        lg={
          medioSelected &&
          (medioSelected.nombre === "Tarjeta Crédito" ||
            medioSelected.nombre === "Tarjeta Débito")
            ? 3
            : 4
        }
        xl={
          medioSelected &&
          (medioSelected.nombre === "Tarjeta Crédito" ||
            medioSelected.nombre === "Tarjeta Débito")
            ? 3
            : 4
        }
      >
        <FormControl size={"small"} fullWidth variant="outlined">
          <InputLabel id={"md-lbl"}>Medio pago</InputLabel>
          <Select
            labelId="md-lbl"
            id="select-md"
            value={filters.medioPago || ""}
            onChange={(event) => dataMP.handleChangeMD(event)}
            label="Medio pago"
          >
            <MenuItem value="">Todos los medios</MenuItem>
            {dataMP.mediosPago.map((item, index) => (
              <MenuItem key={index} value={item.idMedioPago}>
                {item.nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {medioSelected &&
        (medioSelected.nombre === "Tarjeta Crédito" ||
          medioSelected.nombre === "Tarjeta Débito") && (
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <FormControl size={"small"} fullWidth variant="outlined">
              <InputLabel id={"md-lbl"}>Tarjeta</InputLabel>
              <Select
                labelId="tr-lbl"
                id="select-tr"
                value={filters.tarjetaSelect || ""}
                onChange={(event) => dataTarjeta.handleChangeTarjeta(event)}
                label="Tarjeta"
              >
                <MenuItem value="">Todas las tarjetas</MenuItem>
                {dataTarjeta.tarjetas.map((item, index) => (
                  <MenuItem key={index} value={item.id_tarjeta}>
                    {item.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      <Grid
        item
        xs={12}
        sm={12}
        md={
          medioSelected &&
          (medioSelected.nombre === "Tarjeta Crédito" ||
            medioSelected.nombre === "Tarjeta Débito")
            ? 3
            : 4
        }
        lg={
          medioSelected &&
          (medioSelected.nombre === "Tarjeta Crédito" ||
            medioSelected.nombre === "Tarjeta Débito")
            ? 3
            : 4
        }
        xl={
          medioSelected &&
          (medioSelected.nombre === "Tarjeta Crédito" ||
            medioSelected.nombre === "Tarjeta Débito")
            ? 3
            : 4
        }
      >
        <FormControl size={"small"} fullWidth variant="outlined">
          <InputLabel id={"tc-lbl"}>Tipo comprobante</InputLabel>
          <Select
            labelId="tc-lbl"
            id="select-tc"
            value={filters.tipoComprobante || ""}
            onChange={(event) => dataTC.handleChangeTC(event)}
            label="Tipo comprobante"
          >
            <MenuItem value="">Todos tipos comprobante</MenuItem>
            {dataTC.tiposComprobante.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.nombre}
              </MenuItem>
            ))}
            <MenuItem value="factAgrupada">Factura agrupada</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <FormControl fullWidth>
          <Autocomplete
            loading={loading}
            value={dataClient.useClient.client}
            autoHighlight
            options={dataClient.useClient.clients}
            filterOptions={(options, state) => options}
            getOptionLabel={(cliente) => cliente.cliente.razonSocial}
            inputValue={dataClient.useQuery.query || ""}
            onInputChange={(event, value) => {
              dataClient.useQuery.setQuery(value);
            }}
            onChange={(event, value) => dataClient.useClient.setClient(value)}
            renderInput={(params) => (
              <TextField {...params} label="Cliente" variant="outlined" />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <TextField
          select
          fullWidth
          size="small"
          variant="outlined"
          name="tipoDeCliente"
          style={{ marginTop: 5 }}
          value={tipoCliente}
          label="Tipo de cliente"
          onChange={(e) => setTipoCliente(e.target.value)}
        >
          <MenuItem value="">Todos</MenuItem>
          {tiposCliente.length > 0 &&
            tiposCliente.map((tipoCliente) => {
              return (
                <MenuItem key={tipoCliente.id} value={tipoCliente.id}>
                  {tipoCliente.nombre}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid> */}
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <FormControl fullWidth>
          <Autocomplete
            loading={loading}
            value={dataEmpleado.useEmpleado.empleado}
            options={dataEmpleado.useEmpleado.empleados}
            filterOptions={(options, state) => options}
            getOptionLabel={(empleado) =>
              empleado.nombre + " " + empleado.apellido
            }
            inputValue={dataEmpleado.useQueryEmpleado.query || ""}
            onInputChange={(event, value) => {
              dataEmpleado.useQueryEmpleado.setQuery(value);
            }}
            onChange={(event, value) =>
              dataEmpleado.useEmpleado.setEmpleado(value)
            }
            renderInput={(params) => (
              <TextField {...params} label="Empleado" variant="outlined" />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormControl size={"small"} fullWidth variant="outlined">
          <Autocomplete
            loading={loading}
            value={dataTipoVenta.useTipoVenta.tipoDeVenta}
            options={dataTipoVenta.useTipoVenta.tiposDeVenta}
            getOptionLabel={(tipoVenta) => tipoVenta.descripcion}
            inputValue={dataTipoVenta.useQueryTipoVenta.query || ""}
            onInputChange={(event, value) => {
              dataTipoVenta.useQueryTipoVenta.setQuery(value);
            }}
            onChange={(event, value) =>
              dataTipoVenta.useTipoVenta.setTipoDeVenta(value)
            }
            renderInput={(params) => (
              <TextField {...params} label="Tipo de venta" variant="outlined" />
            )}
            name="tipo_ventas"
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <FormControl size={"small"} fullWidth variant="outlined">
          <InputLabel id={"tc-lbla"}>Mostrar</InputLabel>
          <Select
            labelId="tc-lbla"
            id="select-tca"
            value={dataNC.conNotaCredito}
            onChange={(event) => {
              dataNC.setConNotaCredito(event.target.value);
            }}
            label="Mostrar"
          >
            <MenuItem value="all">Todas las ventas</MenuItem>
            <MenuItem value="1">Con nota de crédito</MenuItem>
            <MenuItem value="0">Sin nota de crédito</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <FormControl fullWidth>
          <Autocomplete
            fullWidth
            // autoHighlight={configGeneral.es_autoseleccionado}
            loading={dataArticle.loadingArticulos}
            value={dataArticle.article}
            options={dataArticle.articulos}
            filterOptions={(options, state) => options}
            getOptionLabel={(option) =>
              `${option.codProveedor} - ${option.descripcionProveedor}`
            }
            getOptionSelected={(option) =>
              `${option.codProveedor} - ${option.descripcionProveedor}`
            }
            renderOption={(articulo) =>
              `${articulo.codProveedor} - ${articulo.descripcionProveedor}`
            }
            inputValue={dataArticle.busquedaArticulo}
            onInputChange={(event, value) => {
              dataArticle.setBusqueda(value);
            }}
            onChange={(event, value) => dataArticle.setArticle(value)}
            renderInput={(params) => (
              <TextFieldAutocompleteArticulo
                {...params}
                placeholder="Búsqueda por código o descripción"
                variant="outlined"
                size="small"
                label="Artículo"
              />
            )}
            name="buscador_articulos"
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={3}
        style={{ paddingBottom: 15 }}
      >
        <FiltroBuscador
          listfilters={dataFiltro.listfilters}
          filter={dataFiltro.filter}
          setFilter={dataFiltro.setFilter}
        />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <div>
          <FiltroStock
            filter={
              !checks.checkedArt &&
              !checks.sinStock &&
              !checks.soloStock &&
              !checks.tieneMeli
                ? 1
                : checks.checkedArt &&
                    !checks.soloStock &&
                    !checks.sinStock &&
                    !checks.tieneMeli
                  ? 2
                  : checks.checkedArt &&
                      checks.soloStock &&
                      !checks.sinStock &&
                      !checks.tieneMeli
                    ? 3
                    : checks.checkedArt &&
                        !checks.soloStock &&
                        checks.sinStock &&
                        !checks.tieneMeli
                      ? 4
                      : !checks.checkedArt &&
                          !checks.sinStock &&
                          !checks.soloStock &&
                          checks.tieneMeli
                        ? 5
                        : 1
            }
            setFilter={(value) => {
              localStorage.setItem("filtroMostrar", value);
              switch (value) {
                case 1:
                  handleChangeFiltro.handleChangeArt(true);
                  break;
                case 2:
                  handleChangeFiltro.handleChangeTodosMisArt(true);
                  break;
                case 3:
                  handleChangeFiltro.handleChangeSoloStock(true);
                  break;
                case 4:
                  handleChangeFiltro.handleChangeSinStock(true);
                  break;
                case 5:
                  handleChangeFiltro.handleChangeConVinculacionMeli(true);
                  break;
                default:
                  break;
              }
            }}
          />
        </div>
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <Autocomplete
          fullWidth
          value={filters.provincia || ""}
          options={filterProvincias()}
          getOptionSelected={(provincia) => provincia.nombre}
          getOptionLabel={(provincia) => provincia.nombre}
          noOptionsText={"No se encontraron resultados"}
          onInputChange={(event, value, reason) =>
            setDescripcionProvincia(value)
          }
          onChange={setProvincia}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              style={{ backgroundColor: "white" }}
              label="Provincia"
              variant="outlined"
            />
          )}
          name="provincia"
        />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <AutocompleteTransporteFiltro
          transporteSelected={filters.transporte}
          setTransporteSelected={handleChangeTransporte}
        />
      </Grid>
    </>
  );
}
